body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes win {
  from {
    background-color: #6666ff;
  }
  10% {
    background-color: #0099ff;
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-1.5px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(1.5px, 1px) rotate(0deg);
  }

  40% {
    transform: translate(.5px, -.5px) rotate(1deg);
  }
  50% {
    background-color: #00ff00;
    transform: translate(-1px, 2px) rotate(-1deg);
  }  

  60% {
    transform: translate(-1.5px, .5px) rotate(0deg);
  }

  70% {
    transform: translate(1.5px, .5px) rotate(-1deg);
  }

  80% {
    transform: translate(-.5px, -.5px) rotate(1deg);
  }

  90% {
    transform: translate(.5px, 1px) rotate(0deg);
  }
  75% {
    background-color: #ff3399;
  }
  100% {
    background-color: #6666ff;
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.win {
  animation: win 0.6s;
  animation-iteration-count: infinite;
}

.centered {
  text-align: center;
}

h2{
  font-size: 2vw;
}

h3{
  font-size: 1.4vw;
}

h4{
  font-size: 1.25vw;
}

.title {
  font-size: 5vw;
  font-weight: bold;
}

.text-justify{
  text-justify: auto;
}

button:hover {
  opacity: 1;
}

input {
  border-top-style: hidden;
  appearance: none;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  font-size: 1.4vw;
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.mt-3{
  margin-top: 1% !important;
}

.mt-4{
  margin-top: 2% !important;
}

.mt-6{
  margin-top: 6.4% !important;
}

@keyframes shakelight {
  0% {
    transform: translate(0.5px, 0.5px) rotate(0deg);
  }

  10% {
    transform: translate(-0.5px, -1px) rotate(-1deg);
  }

  20% {
    transform: translate(-1.5px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(1.5px, 1px) rotate(0deg);
  }

  40% {
    transform: translate(0.5px, -0.5px) rotate(1deg);
  }

  50% {
    transform: translate(-0.5px, 1px) rotate(-1deg);
  }

  60% {
    transform: translate(-1.5px, 0.5px) rotate(0deg);
  }

  70% {
    transform: translate(1.5px, 0.5px) rotate(-1deg);
  }

  80% {
    transform: translate(-0.5px, -0.5px) rotate(1deg);
  }

  90% {
    transform: translate(0.5px, 1px) rotate(0deg);
  }

  100% {
    transform: translate(0.5px, -1px) rotate(-1deg);
  }
}

@keyframes shakeheavy {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.shakelight {
  animation: shakelight 0.2s;
  animation-iteration-count: infinite;
}

.shakeheavy {
  animation: shakeheavy 0.2s;
  animation-iteration-count: infinite;
}
