.mt-5{
    margin-top: 5% !important;
}

.playerNumber{
    border-radius: 7px;
    border: .5vw solid black;
    padding-right: 1%;
    padding-bottom: 0.1%;
    text-align: right;
    font-size: 3.5vw;
    padding-right: 1.7%;
}

#playerOneNumber {
    margin-right: 40%;
    margin-left: 5%;
    color: red;
}

#playerTwoNumber {
    margin-left: 40%;
    margin-right: 3%;
    color: blue;
}

#scoreNumber{
    margin-left: 40%;
    margin-right: 3%;
    color: green;

}