.list-group-item{
    border: none;
    padding: 0.5%;
    width: 100%;
}

.buttonContainer {
    padding-top: 1.3%;
    padding-left: 4%;
    padding-right: 4%;
    width: 100%;
}

.exitButton{
    margin-bottom: .4%;
    margin-right: .8%;
    max-width: 10%;
    max-height: 5%;
}

.playerDisplay{
    max-width: 50%;
}