#playerOneInfo {
    font-size: 1.5vw;
    color: red;
}

#playerTwoInfo {
    font-size: 1.5vw;
    color: blue;
}

#score {
    font-size: 2.5vw;
    color: green;
}

.playerInfo{
    padding-left: 1%;
    padding-right: 1%;
    padding-top: .4%;
}