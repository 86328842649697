.menuButton {
    border-radius: .5vw;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    opacity: .9;
}

.smallFont{
  font-size: 1vw;
}

.normalFont{
  font-size: 1.7vw;
  min-height: 3.5vw;
  width: 12vw;
  max-height: 6vw;
}