#goalNumber {
  border-radius: 7px;
  border: .5vw solid black;
  padding-right: 1%;
  padding-bottom: 0.1%;
  width: 23%;
  max-width: 1800px;
  text-align: center;
  font-size: 3.5vw;
  display: inline-block;
  color: black;
}