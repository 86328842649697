.calculatorButton {
  border: 1px solid black;
  color: black;
  border-radius: 7%;
  width: 100%;
  height: 6vw;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 3vw;
  opacity: 0.9;
  padding-top: 5%;
  margin-top: 8%;
}

.selectedNone {
  border: 1px solid black;
}

.selectedPlayerOne {
  opacity: 1;
  border: .3vw solid red;
}

.selectedPlayerTwo {
  opacity: 1;
  border: .3vw solid blue;
}

.powerButton {
  padding-top: .6vw;
}